export const REDIRECT_LOGGED_IN = '/';
export const DEFAULT_ITEMS_PER_PAGE = 20;
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATETIME_FORMAT_SHOW = 'YYYY-MM-DD HH:mm';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const API_ROOT = {
  external_atg: '/trais-api/v1/private/',
  external_atg_international: '/trais-api/v1/private/',
  external_bet25: '/trais-api/v1/private/',
  external_rikstoto: '/trais-api/v1/private/',
  external_tr_spel_b2b: `/trais-api/v1/private/`,
  andelsspel: `/trais-api/v1/private/`,
  galopptips: `/gaais-api/v1/private/`,
  joker_sport: `/trsport-api/v1/private/`,
  jokersystemet: `/trais-api/v1/private/`,
  jokersystemet_no: `/trais-api/v1/private/`,
  overodds: `/trsport-api/v1/private/`,
  spelvarde: `/trais-api/v1/private/`,
  travronden: `/trais-api/v1/private/`,
  travronden_spel: `/trais-api/v1/private/`,
  travronden_fi: `/trais-api/v1/private/`,
  trais: `/trais-api/v1/private/`,
  trhub: `/trais-api/v1/private/`,
  trmedia: `/trais-api/v1/private/`,
};
export const SITE_URLS = {
  jokersystemet: process.env.REACT_APP_JOKERSYSTEMET_URL,
  jokersystemet_no: process.env.REACT_APP_JOKERSYSTEMET_NO_URL,
  overodds: process.env.REACT_APP_OVERODDS_URL,
  spelvarde: process.env.REACT_APP_SPELVARDE_URL,
  storavinster: process.env.REACT_APP_STORAVINSTER_URL,
  travfakta: process.env.REACT_APP_TRAVFAKTA_URL,
  travronden: process.env.REACT_APP_TRAVRONDEN_URL,
  travronden_spel: process.env.REACT_APP_TRAVRONDEN_SPEL_URL,
  travronden_fi: process.env.REACT_APP_TRAVRONDEN_FI_URL,
  travogat: process.env.REACT_APP_TRAVOGAT_URL,
  trmedia: process.env.REACT_APP_TRMEDIA_URL,
};
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const { REACT_APP_TRMEDIA_API_KEY } = process.env;
export const TYPES_FETCH = 'app/containers/App/TYPES_FETCH';
export const TYPES_FETCH_SUCCESS = 'app/containers/App/TYPES_FETCH_SUCCESS';
export const SET_SITE = 'app/containers/App/SET_SITE';
